<template>
    <div class="loginFirst-Con">
        <div class="login-tit">选择您要进入的乡镇/街道</div>
        <div class="login-btn" @click="btnClick()">{{showValue}}</div>
        <div class="login-line"></div>
        <div class="login-click" v-show="!picShow" @click="infoClick()">立即进入</div>
        <van-picker
            v-show="picShow"
            class="login-pic"
            title=""
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
            
        />
    </div>
</template>
<script>
import { Toast } from 'vant';
import { Notify } from 'vant';
// import { requestList } from '@/api/loginFirst';
import request from '../../api/request'
import * as dd from 'dingtalk-jsapi';
import VConsole from 'vconsole/dist/vconsole.min.js';
export default {
    name:"loginFirst",
    data(){
        return{
            scripToken:"",
            tenantId:"",
            showValue:"",
            picShow:false,
            columns: [],
        }
    },
    created(){
        // let vConsole = new VConsole();
    },
    mounted(){
        this.dingdingLogin()
    },
    methods:{
        dingdingLogin() {
            const this_ = this;
            if (dd.env.platform == 'notInDingTalk') {
            } else {
                dd.ready(function() {
                    // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
                    dd.runtime.permission.requestAuthCode({
                        corpId: 'ding6de685f098084e97a1320dcb25e91351', // 惊奇网络
                        // corpId: 'dingf9b677ca8efb19034ac5d6980864d335', // 梅城
                        onSuccess: function(result) {
                            request({
                                url: `/blade-party-admin/min/dingtalk/login-dingtalk-code`,
                                data:{
                                    code: result.code
                                },
                                headers: {
                                    'Authorization': 'Basic c3dvcmQ6c3dvcmRfc2VjcmV0'
                                },
                                method: 'POST',
                            }).then(res => {
                                console.log(res);
                                if (res.success) {
                                    if(res.data.tenants.length==0){
                                        Notify({ type: 'primary', message: '暂无开通租户!' });
                                    }else{
                                        this_.columns=res.data.tenants
                                        this_.scripToken=res.data.scripToken
                                        this_.showValue=res.data.tenants[0].text
                                        this_.tenantId=res.data.tenants[0].tenantId
                                        // localStorage.setItem('Blade-Auth-Token',res.data.scripToken);
                                        localStorage.setItem(
                                            'Blade-Auth-Token',
                                            `bearer ${res.data.scripToken}`
                                        );
                                    }
                                }
                            });
                        }
                    });
                })
            }
        },
        infoClick(){
            const this_ = this;
            console.log(this_.scripToken)
            request({
                url: `/blade-party-admin/min/dingtalk/form`,
                data: {
                    tenantId:this_.tenantId
                },
                method: 'POST',
                headers: {
                    'Authorization': 'Basic c3dvcmQ6c3dvcmRfc2VjcmV0'
                }
            }).then(res=>{
                if (res.success) {
                    const result = res.data;
                    localStorage.setItem(
                        'Blade-Auth-Token',
                        `${result.token_type} ${result.access_token}`
                    );
                    localStorage.setItem('roleName', result.role_name);
                    localStorage.setItem('Avatar', result.avatar);
                    localStorage.setItem('userName', result.user_name);
                    localStorage.setItem('nickName', result.nick_name);
                    localStorage.setItem('tenantId', this_.tenantId);
                    Notify({ type: 'success', message: '登录成功' });
                    this_.$router.push({
                        name: 'mainPage'
                    });
                }
            })
        },
        btnClick(){
            this.picShow=true
        },
        onConfirm(value, index) {
            this.picShow=false
            // Toast(`当前值：${value}, 当前索引：${index}`);
            this.showValue=this.columns[index].text
            this.tenantId=this.columns[index].tenantId
        },
        // onChange(picker, value, index) {
        //     Toast(`当前值：${value}, 当前索引：${index}`);
        // },
        onCancel() {
            this.picShow=false
            Toast('取消');
        },
    }
}
</script>
<style lang="scss" scoped>
$px: 1rem/75;
.loginFirst-Con{
    position: relative;
    .login-tit{
        text-align: center;
        font-size: 40 * $px;
        margin-bottom: 40 * $px;
        margin-top: 300 * $px;
    }
    .login-btn{
        font-size: 30 * $px;
        text-align: center;
        color: #999999;
        margin-bottom: 20 * $px;
    }
    .login-line{
        height: 1 * $px;
        background-color: #999999;
    }
    .login-click{
        border-radius: 10*$px;
        height: 80 * $px;
        width: 90%;
        text-align: center;
        line-height: 80 * $px;
        background-color: #1677FF;
        margin-left: 5%;
        color: #FFFFFF;
        font-size: 35 * $px;
        margin-top: 750*$px;
    }
    .login-pic{
        position: absolute;
        top: 90%;
        width: 100%;
    }
}
</style>